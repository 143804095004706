export const subscriptionsProd = [
  {
    subscriptionMonths: "Annual",
    text2: "Only £8.33 per month",
    text3: "",
    subscriptionPrice: "£99.99",
    // stripePriceId: "price_1OBM2eEAc9CrTQrBEYMuulHC",
    // stripePriceId: "price_1OAtpMEAc9CrTQrB0sIzJZGs",
    // stripePriceId: "price_1O71WZEAc9CrTQrBZRJFjiZG", // default
    stripePriceId: "price_1KFJx9EAc9CrTQrBOISz2BEq",
    stripeProdID: "prod_GS6yRiDxfir5g7",
    appCode: "com.strongandsxy.annual",
    tagging: "subscription - buy - com.strongandsxy.annual",
    subscriptionType: "Yearly",
    trialEnd: 0,
    salePrice: "",
    bestValue: true,
  },
  {
    subscriptionMonths: "Monthly",
    text2: "With 7 Day Free Trial",
    text3: "",
    subscriptionPrice: "£17.99",
    // stripePriceId: "price_1OAtr2EAc9CrTQrBIJVX8doj",
    // stripePriceId: "price_1K6tv2EAc9CrTQrBeHYRqXvX", //default
    stripePriceId: "price_1QuZCuEAc9CrTQrBtxebs4aP",
    stripeProdID: "prod_GS6ir4IkNhHl8P",
    appCode: "com.strongandsxy.monthly",
    tagging: "subscription - buy - com.strongandsxy.monthly",
    subscriptionType: "Monthly",
    trialEnd: 7,
    salePrice: "",
  },
  {
    subscriptionMonths: "Test Yearly",
    text2: "With 14 Day Free Trial",
    text3: "",
    subscriptionPrice: "£12.99",
    stripePriceId: "price_1O7f0kEAc9CrTQrBUJmFJLOa",
    stripeProdID: "prod_GS6ir4IkNhHl8P",
    dollarPriceIOS: "$16.99",
    dollarPriceAndroid: "$14.99",
    appCode: "com.strongandsxy.monthly",
    tagging: "subscription - buy - test.com.strongandsxy.monthly",
    subscriptionType: "Monthly",
    trialEnd: 7,
    isTest: true,
    salePrice: "",
  },
];