export const guidesProd = [
  // Lisa & Romane
  {
    title: "Lisa's Gym Express",
    subtitle: "8 Week Weightlifting Guide",
    description:
      "STRNG express guides are for individuals who only have a short amount of time to workout each day but want to achieve maximum results. LISA’S GYM EXPRESS offers an upper/lower training split to make sure you’re hitting every muscle group with adequate intensity, whether you train at home or in the gym. The guide is devised into a week A & week B format and will be 4 x weekly and no more than 30 - 45 mins. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "8 Week Training Plan",
      "4 workouts a week, training every body part! You’ll train arms, chest, shoulders, abs, legs, and back",
      "Detailed with supersets, drop-sets and sets to failure",
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "LisaGymGuide4x.webp",
    path: "/guides/lisa-gym-express",
    productId: "lisa_8_week_4_day_gym",
    stripeId: "price_1PiwWKEAc9CrTQrBHBrrMk6V",
    price: "29.99",
    androidPrice: "£16.49 + 20% tax",
    stripeProdID: "prod_L2dmo8zLhWOzxT",
    revenueCat: "STRNG_20_lisa_8_week_4_day_gym",
    tagging: "guides - view - STRNG_20_lisa_8_week_4_day_gym",
    ios: "com.strongandsxy.lisa_8_week_4_day_gym",
    android: "com.strongandsxy.lisa_8_week_4_day_gym",
  },
  {
    title: "Romane Shred",
    subtitle: "8 Week Weightlifting Guide",
    description:
      "ROMANE SHRED GUIDE is designed for anyone who is serious about getting completely shredded. This plan best emulates what I do to prepare for shows to get me as shredded as possible whilst maintaining muscle mass. ROMANE SHRED GUIDE includes 6 workouts per week. Training is 6 days per week. Sunday is a rest day. Follow the plan as best you can and remember to share your progress with us on our Instagram by tagging our page @strngofficial_ in your pictures and using the hashtag #STRNGSHRED. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "8 Week Training Plan",
      "6 workouts a week, training every body part! You’ll train arms, chest, shoulders, abs, legs, and back",
      "Detailed with supersets, drop-sets and sets to failure",
      "Access to STRNG’s supportive Facebook group",
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "RomaneShred5x.webp",
    path: "/guides/romane-shred",
    productId: "romane_8_week_5_day_shred",
    stripeId: "price_1PiwRwEAc9CrTQrB1uI84FLP",
    stripeProdID: "prod_L2dmQxy00pZgo2",
    revenueCat: "STRNG_20_romane_8_week_4_day_gym",
    tagging: "guides - view - STRNG_20_romane_8_week_4_day_gym",
    ios: "com.strongandsxy.romane_8_week_4_day_gym",
    android: "com.strongandsxy.romane_8_week_4_day_gym",
    price: "39.99",
    androidPrice: "£24.99 + 20% tax",
  },
  {
    title: "Lisa Lean",
    subtitle: "8 Week Weightlifting Guide",
    description:
      "LISA LEAN is an 8 week weightlifting guide. This plan is designed to help you strip body fat whilst maintaining and increasing muscle size to help you achieve that lean, toned and sculpted look. The programme includes high intensity workouts that will test your limits. The workouts are in the higher rep range between 12 -30 (hypertrophy) to help keep the heart rate up during the session. You will include LISS (low intensity steady state) cardio 5 x per week for 30 mins after your weights session. You will need to make sure you’re eating in a calorie deficit alongside this type of training to ensure you reach your desired goal. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "8 weeks training",
      "5 x weekly",
      "Training split between cardio and weights",
      "Switch option for alternate exercises",
      "The ability to mark your sets as complete",
      "Log weight lifted",
      "Offline option",
      "Access to Lisa’s supportive Facebook group",
      "Videos of Lisa demonstrating each exercise within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "LisaLean5x-V1.webp",
    path: "/guides/lisa-lean",
    productId: "lisa_8_week_5_day_lean",
    stripeId: "price_1PiwyGEAc9CrTQrByRtbjFDq",
    price: "39.99",
    androidPrice: "£24.99 + 20% tax",
    stripeProdID: "prod_KmT10IXyTLHUPJ",
    revenueCat: "STRNG_20_lisa_8_week_5_day_lean",
    tagging: "guides - view - STRNG_20_lisa_8_week_5_day_lean",
    ios: "com.strongandsxy.lisa_8_week_5_day_lean",
    android: "com.strongandsxy.lisa_8_week_5_day_lean",
  },
  {
    title: "Romane Lean Bulking",
    subtitle: "6 Week Weightlifting Guide",
    description:
      "ROMANE LEAN BULKING GUIDE is designed for anyone who is serious about gaining muscle and staying lean whilst doing it. This plan best emulates what I do between shows to gain extra mass. Diet and training is for 6 days per week. Sunday is a rest day. Follow the plan as best you can and remember to share your progress with us on Instagram by tagging us in your pictures and using the hashtag #STRNG. ROMANE LEAN BULKING GUIDE includes 6 workouts spread over 6 weeks. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "6 Week Training Plan",
      "5 workouts a week, training every body part! You’ll train arms, chest, shoulders, abs, legs and back. This plan combines hypertrophy with strength training to give your physique size and detail at the same time.",
      "Detailed with supersets, dropsets and sets to failure",
      "Access to STRNG’s supportive Facebook group",
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "RomaneLeanBulking5x.webp",
    path: "/guides/romane-lean-bulking",
    productId: "romane_8_week_5_day_lean_bulking",
    stripeId: "price_1PiwOsEAc9CrTQrBkG5pySG0",
    price: "39.99",
    androidPrice: "£24.99 + 20% tax",
    stripeProdID: "prod_OsOi0Rf2An8Z5b",
    revenueCat: "STRNG_20_romane_8_week_5_day_gym",
    tagging: "guides - view - STRNG_20_romane_8_week_5_day_gym",
    ios: "com.strongandsxy.romane_8_week_5_day_gym",
    android: "com.strongandsxy.romane_8_week_5_day_gym",
  },
  {
    title: "Lisa STRNG",
    subtitle: "8 Week Weightlifting Guide",
    description:
      "The LISA STRNG guide is an 8 week weightlifting guide. The plan is designed for anyone who is looking to build lean muscle and get STRNG. Through specific weight training principles and exercise selection this plan will help you build and sculpt your physique and achieve that STRNG look. The STRNG guide is a specific type of training in order to build strength and increase muscle size. Training in a lower rep range between 4 - 8 reps and aiming to lift a heavy weight relative to you. It is devised into a PUSH/PULL/LEGS training split and training 5 x per week. Compound movements are the focus and you'll be doing the same type of compound exercises (and there are only a handful) but this is also a part of the discipline to focus and do something over to get results. The heavy lifts overload the muscles so they have to adapt to the weight you're using and stress you're putting them under, which in turn increases strength and muscle size. This is how you build strength and muscle size alongside eating in a calorie surplus. It's best not to do cardio while you're aiming to gain, but if you feel you want to, please only add in LISS (low intensity steady state) on the treadmill for 10 mins 3x per week. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "8 Weeks training",
      "5 x weekly",
      "Training split - PUSH/PULL/LEGS",
      "LISS cardio on treadmill 10 mins x 3 per week",
      "Switch option for alternate exercises",
      "Mark your sets as complete",
      "Log weight lifted",
      "Offline option",
      "Access to Lisa’s supportive Facebook group",
      "Videos of Lisa demonstrating each exercise within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "Strong5x-V1.webp",
    path: "/guides/lisa-strong",
    productId: "lisa_8_week_5_day_strong",
    stripeId: "price_1PiwnbEAc9CrTQrBhkoJYpOj",
    price: "39.99",
    androidPrice: "£24.99 + 20% tax",
    stripeProdID: "prod_KmT3KpK9mkaqRs",
    revenueCat: "STRNG_20_lisa_8_week_5_day_strong",
    tagging: "guides - view - STRNG_20_lisa_8_week_5_day_strong",
    ios: "com.strongandsxy.lisa_8_week_5_day_strong",
    android: "com.strongandsxy.lisa_8_week_5_day_strong",
  },
  {
    title: "Lisa Glute Guide",
    subtitle: "8 Week Weightlifting Guide",
    description:
      "LISA GYM GLUTE GUIDE is an 8 week weightlifting guide. This plan is designed to help you build that boootay! The plan includes 3 training sessions per week for you to fit into your current training schedule. It is a mixture between low reps and high reps for maximum glute gains and concentrates on compound lifts! The sessions should take around 70 minutes. It is also essential to be eating in a calorie surplus alongside this training plan in order to promote muscle growth. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "8 Weeks training",
      "3 x weekly",
      "Glute band needed for this guide (you can purchase a STRNG glute band on our website)",
      "Switch option for alternate exercises",
      "Videos of Lisa demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted",
      "Offline option",
    ],
    img: "GluteGuide3x-V1.webp",
    path: "/guides/lisa-glute-guide",
    productId: "lisa_8_week_3_day_glute",
    stripeId: "price_1PiwehEAc9CrTQrBaxXkvWnG",
    price: "39.99",
    androidPrice: "£24.99 + 20% tax",
    stripeProdID: "prod_KmT4ekYBctAFZn",
    revenueCat: "STRNG_20_lisa_8_week_3_day_glute",
    tagging: "guides - view - STRNG_20_lisa_8_week_3_day_glute",
    ios: "com.strongandsxy.lisa_8_week_3_day_glute",
    android: "com.strongandsxy.lisa_8_week_3_day_glute",
  },

  // Andrea
  {
    title: "ANDREA'S SHAPE & SHRED GUIDE",
    subtitle: "6 Week Weight Loss Home Guide",
    description:
      'SHAPE & SHRED GUIDE is a home weight loss guide; it gives you the chance to burn calories in the comfort of your own house. This guide is intended for people who are pressed for time or who might not feel at ease in a gym setting. If that sounds familiar, this guide is for you! This 6 week guide offers you a full body and core split to make sure that you are hitting every muscle group.  The training plan includes 5 training days per week with a mixture of reps and timed intervals. The sessions should take up to 45-60 minutes. To make sure you are working every muscle group in your body, this 6 week plan offers you a complete body and core split.  The training schedule calls for 5 days of training each week, mixed with repetitions and circuit inspired sets. The sessions should take you between 45–60 minutes to complete. The goal of the program is to improve your overall health and burn calories in the comfort of your home. ',
    features: [
      "6 Week Training Plan",
      "6 workouts a week, training every body part! You’ll train arms, chest, shoulders, abs, legs, and back",
      "Full body and core split",
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "650x800_shape_shed_bright-min.png",
    path: "/guides/andrea-shape-and-shred",
    productId: "STRNG_20_andrea_home",
    stripeId: "price_1PiwIlEAc9CrTQrBtY2u0lbv",
    price: "39.99",
    stripeProdID: "prod_P6HZi9yGWMbTv5",
    revenueCat: "STRNG_20_andrea_home",
    ios: "com.strongandsxy.andrea_home_new",
    android: "com.strongandsxy.andrea_home_new",
  },
  {
    title: "ANDREA'S GAINS GUIDE",
    subtitle: "6 Week Weightlifting Gym Guide",
    description:
      'GAINS GUIDE is a 6 week weightlifting guide. The goal of the program is to enhance your overall health while assisting you in testing and developing your maximal strength. Throughout the guide you will be pushing yourself to burn calories and build muscle, so it is essential to compliment this guide with a nutritional plan for optimal results. If you need guidance, STRNG subscriptions offer a dedicated section for meals (under the MEALS tab) that will give you a full calorie breakdown with macro split to help you with this.  The guide includes 5 training days and 2 rest days per week. Training days are divided into push and pull days as well as muscle types. To keep the body guessing, the reps vary from 5 to 20 reps. The duration of the sessions may vary based on your fitness level, ranging from 70 to 90 minutes. It is essential for you to stay up to par with your water intake and nutrition consumption.',
    features: [
      "6 Week Training Plan",
      "5 workouts a week, training every body part!",
      "Strength & Conditioning",
      "Strive to increase your weights by 5 - 10 lbs per week.",
      "The guide includes 5 training days and 2 rest days per week",
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide",
    ],
    img: "600x600_shape_shed.png",
    path: "/guides/andrea-strength",
    productId: "STRNG_20_andrea_strength",
    stripeId: "price_1PiwLcEAc9CrTQrBdR91rTzn",
    price: "39.99",
    stripeProdID: "prod_P6HY2HZCY2431d",
    revenueCat: "STRNG_20_andrea_strength",
    ios: "com.strongandsxy.andrea_home_strength",
    android: "com.strongandsxy.andrea_home_strength",
  },

  // Tess
  {
    title: "TESS'S MOVEMENTS FOR LIFE VOL. 1 GUIDE",
    subtitle: "6 week Functional Training Gym Guide ",
    description:
      "A straightforward six-week program that's all about making your workouts count. Each session is less than an hour, there’s no fluff, just effective movements seamlessly woven into your daily routine. Experience the impact of functional fitness as it transforms the way you move and feel. It's about practical, efficient, and powerful workouts designed to bring real, tangible results to how you move in your life.",
    features: [
      "6 Weeks training plan",
      "5 x weekly",
      "Functional training guide created to increase your overall strength and fitness.",
      "Switch option for alternate exercises",
      "Videos of Tess demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted"
    ],
    img: "movement.jpg",
    path: "/guides/movements-for-life",
    productId: "STRNG_20_movements_vol_1",
    stripeId: "price_1Piw7DEAc9CrTQrBy6cBjUuP",
    price: "39.99",
    stripeProdID: "prod_PRDq7DAlkMbefI",
    revenueCat: "STRNG_20_movements_vol_1",
    ios: "com.strongandsxy.movements_vol_1",
    android: "com.strongandsxy.movements_vol_1",
  },
  {
    title: "TESS'S CORE BURN GUIDE",
    subtitle: "4 week Core Training Guide",
    description:
      "A 4 week program, designed with functional movements that will push you in the best ways. This program emphasizes precision exercises for noticeable results. Transform your core and experience the practical benefits of enhanced functional strength.",
    features: [
      "4 Weeks training guide",
      "3 x weekly",
      "Designed to strengthen and build your core",
      "Switch option for alternate exercises",
      "Videos of Tess demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted"
    ],
    img: "core_sprint.jpg",
    path: "/guides/core-sprint",
    productId: "STRNG_20_core_burn",
    stripeId: "price_1PiwAbEAc9CrTQrBaT5Li1dR",
    price: "39.99",
    stripeProdID: "prod_PRDqgscztzNHDg",
    revenueCat: "STRNG_20_core_sprint",
    ios: "com.strongandsxy.core_sprint",
    android: "com.strongandsxy.core_sprint",
  },

  // Alexia
  {
    title: "ALEXIA'S GLUTE BUILDER GUIDE",
    subtitle: "9 Week Gym Guide For Glute Growth",
    description:
      "Build some serious muscle in this 9 week Glute Builder Guide. Focussing on working to failure and progressive overload this guide focusses on building your lower body with 3 lower focussed days while still working your upper body with 2 upper focussed days.",
    features: [
      "9 Weeks training guide",
      "5 x weekly",
      "Designed to grow your glutes",
      "Utilising training methods Alexia loves to build her own physique",
      "Switch option for alternate exercises",
      "Videos of Alexia demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted"
    ],
    img: "glute_builder.jpg",
    path: "/guides/glute-builder",
    productId: "STRNG_20_glute_builder",
    stripeId: "price_1Piw3BEAc9CrTQrBgGaleGFJ",
    price: "39.99",
    stripeProdID: "prod_PUDMnhiRnIEEPs",
    revenueCat: "STRNG_20_glute",
    ios: "com.strongandsxy.glutebuilder",
    android: "com.strongandsxy.glutebuilder",
  },
  {
    title: "ALEXIA'S HOURGLASS GUIDE",
    subtitle: "9 Week Gym Guide For An Hourglass Physique",
    description:
      "Looking to build an hourglass physique? This 9 week guide focusses on building both your shoulders and upper body as well as your lower body to give the illusion of a smaller waist. Expect some serious sweat, working until failure and focussing on progressive overload in this guide from Alexia.",
    features: [
      "9 Weeks training guide",
      "5 x weekly",
      "Designed to build an hourglass shape",
      "Utilising training methods Alexia loves to build her own physique",
      "Switch option for alternate exercises",
      "Videos of Alexia demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted",
    ],
    img: "hourglass.jpg",
    path: "/guides/hourglass",
    productId: "STRNG_20_hourglass",
    stripeId: "price_1PivsuEAc9CrTQrByIIeKAJq",
    price: "39.99",
    stripeProdID: "prod_PUDNwj1CgWlmmd",
    revenueCat: "STRNG_20_hourglass",
    ios: "com.stongandsxy.hourglass",
    android: "com.stongandsxy.hourglass",
  },

  // STRNGER Than Ever Challenge Guide
  {
    title: "STRNGER Than Ever Challenge Guide",
    subtitle: "8 Week Transformation Gym Guide",
    description: `The STRNGER Than Ever Challenge Guide is all about pushing you out of your comfort zone, working towards your goals and becoming STRNGER physically and mentally. 

      The programming behind this guide is all about 'non-linear periodization' - essentially switching up your style of training to get the most out of your workouts, keep your body adapting and improving for INCREDIBLE results.

      Featuring all of our expert STRNG trainers, this guide is one that will have you pushing yourself more than ever.`,
    features: [
      "8 Weeks training",
      "5 x weekly",
      "Strength and functional training styles",
      "Switch option for alternate exercises",
      "Videos of all trainers demonstrating each exercise",
      "Mark your sets complete",
      "Log weight lifted",
      "Offline option"
    ],
    img: "STR.jpg",
    path: "/guides/strnger-than-ever",
    productId: "STRNG_20_5daychal",
    stripeId: "price_1PivQAEAc9CrTQrBOygFP6at",
    price: "39.99",
    stripeProdID: "prod_PgeAi0AtVKuNy2",
    revenueCat: "STRNG_20_5daychal",
    ios: "com.strongandsxy.5daychal",
    android: "com.strongandsxy.5daychal",
  },

  // Romane's Full Body Guide
  {
    title: "Romane's Full Body Guide",
    subtitle: "8 Week Full Body Weightlifting Gym Guide",
    description: `Get results by working out just 3 days a week. This is an 8-week program designed for anyone who is short on time who wants to achieve maximum impact. 
This guide offers a full body training split to make sure you’re hitting every muscle group with adequate intensity. You’ll work all muscle-groups within an allotted time, rather than just one specific muscle group. Full body workouts promote a higher calorie burn and have the advantage of working on more functional movements which can contribute towards our quality of life.
This guide is written with training at the gym in mind, however with the ability to swap exercises this guide could also be done at home with access to a range of dumbbells.
This guide can also be unlocked when you start a subscription with the STRNG app.`,
    features: [
      "8 Week training plan",
      "3 workouts a week, working all muscle-groups within each session rather than just one specific muscle group.",
      "Access to STRNG’s supportive Facebook group",  
      "Videos of exercises housed within the STRNG app",
      "Track all your training within the STRNG app",
      "The app is available on iOS and Android worldwide"
    ],
    extraDescription: "Follow the plan as best you can and remember to share your progress with us on Instagram by tagging us in your pictures and using the hashtag #STRNG.",
    img: "RomaneFullBody.jpg",
    path: "/guides/romane-full-body",
    productId: "Prod_Romane_Full_Body_Guide",
    stripeId: "price_1PjGxJEAc9CrTQrBpUZpywrP",
    price: "39.99",
    stripeProdID: "prod_QaRrINGEMKImpO",
    revenueCat: "Prod_Romane_Full_Body_Guide"
  },


  {
    title: "Lisa's Kickstart Body Guide",
    subtitle: "4-Week Full Body Kickstart Gym Guide",
    description: `Kickstart your fitness goals with this 4 week guide designed specifically to give your consistency, fitness journey and motivation the kickstart it needs.
    Whether you're brand new to training, or you've taken some time away, this progressive gym guide is the perfect starting point before moving onto your next guide or plan.`,
    features: [
      "Gym guide",
      "4 weeks",
      "4 workouts per week",  
      "Build confidence",
      "Kickstart your consistency",
      "Swap exercises",
      "Log your lifts"
    ],
    extraDescription: "",
    img: "lisa-kickstart.png",
    path: "/guides/lisa-kickstart",
    productId: "Kickstart_Prod",
    stripeId: "price_1QkhoHEAc9CrTQrBPHE5j6AX",
    price: "19.99",
    stripeProdID: "prod_Rdznm0DvdTldZx",
    revenueCat: "Kickstart_Prod"
  },

  // Test
  {
    title: "My STRNG TEST Product",
    subtitle: "My STRNG TEST Product",
    description:
      "My STRNG TEST ProductTh  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum e surplus alongside this training plan in order to promote muscle growth. This guide can also be unlocked when you start a subscription with the STRNG app.",
    features: [
      "Mark your sets complete",
      "Log weight lifted",
      "Offline option",
    ],
    img: "GluteGuide3x-V1.webp",
    path: "/guides/my-strng-test-product",
    productId: "my_strng_test_product",
    stripeId: "price_1O4SnkEAc9CrTQrBTB8rrnuj",
    tagging: "guides - view - my_strng_test_product",
    price: "0.22",
    isTest: true,
  },
];