import React from "react";
import { useState, useEffect } from "react";

import { useStytchUser, useStytchSession } from "@stytch/react";
import { Navigate } from "react-router-dom";
import "./style.css";

// ** MUI Components
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { List, ListItem, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import SubscriptionItem from "../components/SubscriptionItem";
import { benefits } from "../constants/benefits";
import { subscriptionsProd } from "../constants/subscriptions";
import { subscriptionsStaging } from "../constants/subscriptions-staging";
import { getActiveSubsAndGuides } from "../services/subscription-api";

import { getStytchSessionToken } from "../services/stytch.service";

const API_URL = process.env.REACT_APP_API_URL;
console.log(process.env.REACT_APP_API_URL, "API_URL");
const subscriptions =
  process.env.REACT_APP_VERCEL_ENV === "prod" ||
  process.env.REACT_APP_VERCEL_ENV === "production"
    ? subscriptionsProd
    : subscriptionsStaging;

export const Subscriptions = () => {
  // Get the Stytch User object if available
  //Fetch all active subscriptions for the user.
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [activeGuides, setActiveGuides] = useState([]);
  const [loading, setLoading] = useState(true);

  const { user } = useStytchUser();

  const query = new URLSearchParams(window.location.search);
  const showTestProduct = query.get("showTestProduct");

  const { user_id: stytchId, emails } = user || {};
  const email = emails?.[0]?.email;

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      setLoading(true);

      // fetch the user's active subscriptions
      const subsData = await getActiveSubsAndGuides(user.emails[0].email, getStytchSessionToken());
      if (subsData?.subscriptions) {
        setActiveSubscriptions(subsData.subscriptions);
      }

      // set guides
      if (subsData?.nonSubscriptions) {
        setActiveGuides(subsData.nonSubscriptions);
      }

      setLoading(false);
    };

    // call the function
    if (user) {
      fetchData().catch(console.error);
    }
  }, [user]);

  if (!loading && activeSubscriptions.length) {
    // return <Navigate reloadDocument={true} to="/manage-membership" />;
  }
  return (
    <>
      {!user ? (
        // If the user is logged in, redirect to the member page
        <Navigate to="/auth" />
      ) : (
        <>
          <Box className="content-center">
            <Box style={{ zIndex: 1 }}>
              <div>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h3">subscriptions</Typography>
                </Box>

                <Grid
                  container
                  paddingLeft={2}
                  paddingRight={2}
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Grid item sm="12" md="5">
                    <Typography variant="paraOpen" sx={{ fontWeight: "bold" }}>
                      Become A STRNG member.{" "}
                    </Typography>
                    <Typography variant="paraOpen">
                      Join our community of over 100,000 people who have
                      transformed their lives with STRNG by Lisa and Romane
                      Lanceford.{" "}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sm="12"
                    md="4"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      marginTop: 50,
                    }}
                  >
                    <Typography variant="paraOpen" sx={{ fontWeight: "bold" }}>
                      Membership Benefits:{" "}
                    </Typography>
                    <Box>
                      <List
                        dense={true}
                        sx={{
                          listStyleType: "disc",
                          pl: 2,
                          color: "white",
                          mt: 0,
                          pt: 0,
                        }}
                      >
                        {benefits.map((benefit) => {
                          return (
                            <ListItem
                              sx={{
                                display: "list-item",
                                fontFamily: "Open Sans",
                                paddingLeft: 0,
                                paddingBottom: 0,
                                paddingTop: 0,
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography variant="paraOpen">
                                    {benefit}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Grid>
                  <Grid item sm="12" md="4">
                    <Box
                      style={{
                        width: "100%",
                        marginBottom: "2rem",
                        marginTop: "4rem",
                      }}
                    >
                      <Typography variant="h3">pricing</Typography>
                    </Box>
                    <Box style={{ color: "white", fontSize: 16 }}>
                      <Grid
                        container
                        display={"flex"}
                        justifyContent={"center"}
                        flexDirection={"column"}
                        alignItems={"center"}
                      >
                        {subscriptions
                          .filter((v) => (showTestProduct ? true : !v.isTest))
                          .map(
                            (
                              {
                                subscriptionMonths,
                                tagging,
                                text2,
                                text3,
                                subscriptionPrice,
                                stripePriceId,
                                trialEnd,
                                bestValue,
                                salePrice,
                              },
                              key
                            ) => {
                              return (
                                <SubscriptionItem
                                  subscriptionMonths={subscriptionMonths}
                                  text2={text2}
                                  text3={text3}
                                  subscriptionPrice={subscriptionPrice}
                                  stripePriceId={stripePriceId}
                                  stytchId={stytchId}
                                  trialEnd={trialEnd}
                                  key={key}
                                  tagging={tagging}
                                  bestValue={bestValue}
                                  customerEmail={email}
                                  salePrice={salePrice}
                                />
                              );
                            }
                          )}
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item sm="12" md="4">
                    <Typography variant="paraOpen" sx={{ display: 'none', fontWeight: "300", color: "gray", fontSize: 12 }}>
                      Get 30% off your first year — then renews at full price.
                    </Typography>
                  </Grid>
                  <Grid item sm="12" md="4">
                    <Typography variant="paraOpen" sx={{ marginTop: 3 }}>
                      The free trial is only available for new customers on the monthly 
                      subscription option. You can cancel at anytime during the 
                      free trial and you won’t be charged until the trial completes. 
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};
export default Subscriptions;
